/*
 * Copyright (C) 2018 mcube (contact@mcube.fr)
 * 
 * This work is licensed under the Creative Commons
 * Attribution 3.0 Unported License. To view a copy
 * of this license, visit
 * http://creativecommons.org/licenses/by/3.0/.
 * 
 * When using this software you use it at your own risk. We hold
 * no responsibility for any damage caused by using this plugin
 * or the documentation provided.
 */
(function($){
	$.cookieConsent = function(options,val){
		var defaults = {
			cookieList: ["tech","perf","perso","social"],
			expireDays: 365, //Number of days for cookieBar cookie to be stored for
			effect: 'fade', //Options: slide, fade, hide
			element: 'body', //Element to append/prepend cookieBar to. Remember "." for class or "#" for id.
			zindex: '', //Can be set in CSS, although some may prefer to set here
			domain: String(window.location.hostname), //Location of privacy policy
			referrer: String(document.referrer) //Where visitor has come from
		};
		var options = $.extend(defaults,options);


		// Param
		var lang        = $("html").attr("lang");
		var change      = false;
		var cookieValue = 'done:0!all:0!tech:1!perf:1!perso:1!social:1';
		// var cookieValue = 'done:0!all:0!';

		var showBar = function(){
			$.ajax({
				type: "GET",
				url: "/cookie/"+lang+".html",
				success: function(retour) {
					$('body').append(retour);

					if($.cookie('cookie_consent')) {
						cookieValue = $.cookie('cookie_consent');

						options.cookieList.forEach(function(cookie, index) {
							checked = get(cookie) ? true : false;
							$("input[name='cc-cookie'][value='"+cookie+"']").attr('checked',checked);
						});
					} else {
						$('#cookie-consent .cc-main').show();
						$('#cookie-consent .cc-param').hide();
					}

					if(options.effect=='slide') {
						$('#cookie-consent').slideDown(300);
					} else if(options.effect=='fade') {
						$('#cookie-consent').fadeIn(300);
					} else {
						$('#cookie-consent').show();
					}
				}
			});
		};		

		var get = function(type) {
			cookies = cookieValue.split('!');
			for(var i = 0; i < cookies.length; i++) {
				cookie = cookies[i].split(':');
				if(cookie[0] == type) {
					return parseInt(cookie[1]);
				}
     		}
			return 0;
		};

		var hideBar = function(reload){
			if(options.effect=='slide') {
				$('#cookie-consent').slideUp(300, function() {
					$(this).remove();
				});
			} else if(options.effect=='fade') {
				$('#cookie-consent').fadeOut(300, function() {
					$(this).remove();
				});
			} else {
				$('#cookie-consent').hide(0, function() {
					$(this).remove();
				});
			}
			if(reload) location.reload();
		};

		var cookieAccept = function(){
			var reload = change || !get( 'done' );

			if ( reload ) {
				$.cookie('cookie_consent', cookieValue, {expires: options.expireDays, path: '/'});
			}
			
			hideBar(reload);
		};

		if ( $.cookie( 'cookie_consent' ) ) {
			cookieValue = $.cookie( 'cookie_consent' );
		} else {
			showBar();
		}

		// EVENT CHECK
		$(document).on('click', "#cookie-consent input[type='checkbox']", function() {
			change = true;
		});

		// BTN
		$(document).on('click', '#cookie-consent .cc-btn-accept', function(e) {
			e.preventDefault();
			cookieValue = 'done:1!all:1!tech:1!perf:1!perso:1!social:1';
			change      = true;
			cookieAccept();
		});

		$(document).on('click', '#cookie-consent .cc-btn-done', function(e){
			e.preventDefault();
			cookieValue = 'done:1!all:0';
			$("input[name='cc-cookie']").each(function(index) {
				cookieValue += '!' + $(this).val() + ':' + (0 + $(this).is(':checked'));
			});
			cookieAccept();
		});

		$(document).on('click', '#cookie-consent .cc-btn-param', function(e) {
			e.preventDefault();
			$('#cookie-consent .cc-main').hide();
			$('#cookie-consent .cc-param').show();
		});

		// LINK
		$('.open-rgpd-settings, [cookie-consent]').click(function(e) {
			e.preventDefault();
			showBar();
		})
	};

	$.cookieConsent({
		fixed:      true,
		zindex:     10000
	});

})(jQuery);