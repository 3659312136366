// (function () {
// 	$consoleLog = console.log;
// 	console.log = function ($message, $bg, $color) {
// 		$consoleLog("%c" + $message, "background-color:" + $bg + "; color:" + $color + ";font-weight:bold;padding:3px 5px;");
// 	};
// })();

// console.log(' ');
// console.log('Fabriqué à Lyon par Mcube','#231D34','#ffffff');
// console.log('www.mcube.fr','#FA3348','#ffffff');
// console.log(' ');

(function($) {

var tools = (function() {
	var init = function() {
		bindEvents();
	};
	var bindEvents = function() {
		$('body').on('mousedown', 'img', function() {
			return false;
		});
	};
	var isDesktop = function() {
		return ( $(window).width() >= 992) ;
	};
	var isTablet = function() {
		return ( $(window).width() < 992 && $(window).width() >= 768) ;
	};
	var isSmartphone = function() {
		return ( $(window).width() < 768) ;
	};
	var isIOS = function() {
		var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		return isIOS;
	};
	var isMobile = function() {
		return $('html').hasClass('is-handheld');
	};
	return {
		init: init,
		isDesktop: isDesktop,
		isTablet: isTablet,
		isSmartphone: isSmartphone,
		isIOS: isIOS
	};
})();

var site = (function() {
	var pageOpeningWait = true;
	var siteIsLoading   = true;
	var currentURL      = window.location.href;
	var siteIntroDelay  = 0;
	var initTime        = Date.now();
	var pageHeight      = $(window).innerHeight();
	var init            = function() {
		tools.init();
		bindEvents();
		pageInit();
	};

	/*
	########  ##     ##  ########  ##    ##  ########   ######    
	##        ##     ##  ##        ###   ##     ##     ##    ##   
	##        ##     ##  ##        ####  ##     ##     ##         
	######    ##     ##  ######    ## ## ##     ##      ######    
	##         ##   ##   ##        ##  ####     ##           ##   
	##          ## ##    ##        ##   ###     ##     ##    ##   
	########     ###     ########  ##    ##     ##      ######    
	*/

	var debounce = function(wait, immediate, func) {

		var timeout;

		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	var addEndEvent = debounce(200, false, function(e) {
		$(this).trigger(e.type + 'End');
	});

	var bindEvents = function() {
		// $('[data-link]').click(function(e) {
		// 	linkHandler( $(this) );
		// }).find('a').click(function(e) {
		// 	e.stopPropagation();
		// });

		// $('a').click(function(e) {
		// 	linkHandler( $(this) );
		// });
		$(window).on('resize orientationchange', addEndEvent);
		$(window).on('resizeEnd', resizeHandler);
		$(window).on('scroll', scrollHandler);
		$('body').on('reveal', '.scroll-reveal', scrollRevealHandler);
	};

	/*
	 ######    ####  ########   ########   ##          #######      ###     ########   ########   ########   
	##    ##    ##      ##      ##         ##         ##     ##    ## ##    ##     ##  ##         ##     ##  
	##          ##      ##      ##         ##         ##     ##   ##   ##   ##     ##  ##         ##     ##  
	 ######     ##      ##      ######     ##         ##     ##  ##     ##  ##     ##  ######     ##     ##  
		  ##    ##      ##      ##         ##         ##     ##  #########  ##     ##  ##         ##     ##  
	##    ##    ##      ##      ##         ##         ##     ##  ##     ##  ##     ##  ##         ##     ##  
	 ######    ####     ##      ########   ########    #######   ##     ##  ########   ########   ########   
	*/
	var siteLoaded = function() {
		siteIsLoading = false;
		pageInit();
	};


	/*
	##     ##  ########   ##    ##   ##     ##  
	###   ###  ##         ###   ##   ##     ##  
	#### ####  ##         ####  ##   ##     ##  
	## ### ##  ######     ## ## ##   ##     ##  
	##     ##  ##         ##  ####   ##     ##  
	##     ##  ##         ##   ###   ##     ##  
	##     ##  ########   ##    ##    #######   
	*/


	$('.menu-item a').click(function(e) {
		$('body').removeClass('nav-opened');
	});

	$('.nav-main__open').click(function(e) {
		e.preventDefault();
		$('body').addClass('nav-opened');
	});

	$('.nav-main__close').click(function(e) {
		e.preventDefault();
		$('body').removeClass('nav-opened');
	});

	$('[legals-popup]').click(function(e) {
		e.preventDefault();
		$.ajax({
			type: "GET",
			url: "legals.html",
			success: function(retour) {
				$('body').append(retour);
				$('#legals-popup').fadeIn(300);
			}
		});
	});

	$(document).on('click', '.cc-btn-close', function(e) {
		e.preventDefault();
		$('#legals-popup').fadeOut(300, function() {
			$(this).remove();
		});
	});

	var ctrlNav;
	var sceneNav = [] ;

	var initNav = function() {
		$('body').addClass('ctrlNav');

		ctrlNav = new ScrollMagic.Controller();

		durationPresentation = $('#presentation').outerHeight();
		durationActivite     = $('#activite').outerHeight();
		durationEngagement      = $('#engagement').outerHeight(); 
		durationReference       = $('#reference').outerHeight();
		durationContact        = $('#contact').outerHeight();

		// build scenes
		sceneNav[1] = new ScrollMagic.Scene({triggerElement: "#presentation"})
						.setClassToggle("#menu-presentation", "current")
						.duration(durationPresentation)
						.addTo(ctrlNav);
		sceneNav[2] = new ScrollMagic.Scene({triggerElement: "#activite"})
						.setClassToggle("#menu-activite", "current")
						.duration(durationActivite)
						.addTo(ctrlNav);
		sceneNav[3] = new ScrollMagic.Scene({triggerElement: "#engagement"})
						.setClassToggle("#menu-engagement", "current")
						.duration(durationEngagement)
						.addTo(ctrlNav);
		sceneNav[4] = new ScrollMagic.Scene({triggerElement: "#reference"})
						.setClassToggle("#menu-reference", "current")
						.duration(durationReference)
						.addTo(ctrlNav);
		sceneNav[5] = new ScrollMagic.Scene({triggerElement: "#contact"})
						.setClassToggle("#menu-contact", "current")
						.duration(durationContact)
						.addTo(ctrlNav);
	};

	var resizeNav = function() {
		if ( ! $('body').hasClass('ctrlNav') ) {
			initNav();
		}
		if ( ctrlNav.enabled() ) {
			durationPresentation = $('#presentation').outerHeight();
			durationActivite     = $('#activite').outerHeight();
			durationEngagement      = $('#engagement').outerHeight(); 
			durationReference       = $('#reference').outerHeight();
			durationContact        = $('#contact').outerHeight();

			sceneNav[1].duration(durationPresentation);
			sceneNav[1].update(true);

			sceneNav[2].duration(durationActivite);
			sceneNav[2].update(true);

			sceneNav[3].duration(durationEngagement);
			sceneNav[3].update(true);

			sceneNav[4].duration(durationReference);
			sceneNav[4].update(true);

			sceneNav[5].duration(durationContact);
			sceneNav[5].update(true);
		}
	};
	/*
	##     ##     ###     ##    ##   ########   ##         ########   ########    ######    
	##     ##    ## ##    ###   ##   ##     ##  ##         ##         ##     ##  ##    ##   
	##     ##   ##   ##   ####  ##   ##     ##  ##         ##         ##     ##  ##         
	#########  ##     ##  ## ## ##   ##     ##  ##         ######     ########    ######    
	##     ##  #########  ##  ####   ##     ##  ##         ##         ##   ##          ##   
	##     ##  ##     ##  ##   ###   ##     ##  ##         ##         ##    ##   ##    ##   
	##     ##  ##     ##  ##    ##   ########   ########   ########   ##     ##   ######    
	*/
	var resizeHandler = function() {
		$(window).trigger('scroll');
		initScrollAnimations();
		resizeParallax();
		
		// /* Autres fonctions jouées au resize */
		// resizeSlider();
		if ($('body').hasClass('home')) {
			resizeNav();
		}
		
	};

	var scrollHandler = function() {
		var scrollTop = $(window).scrollTop();
		
		/* EXEMPLE */
		if (scrollTop > 380) {
			$('.scroll-top').addClass('scrolled-btn');
			$('.nav-container').addClass('scrolled');
		}
		else {
			$('.scroll-top').removeClass('scrolled-btn');
			$('.nav-container').removeClass('scrolled');

		}

		if (scrollTop > 420) {
			$('.nav-container').addClass('stuck');
		}
		else {
			$('.nav-container').removeClass('stuck');
		}
	};

	// var linkHandler = function(element) {
	// 	var $link = element;

	// 	var url  = ( $link.attr('href') || $link.attr('data-link'));
		
	// 	if ( $link.hasClass('no-link') ||
	// 		 $link.attr('target') ||
	// 		 url.match('^#') ) {
	// 		return;
	// 	}

	// 	if (url === currentURL)
	// 		return;

	// 	// On envoi l’URL vers le loader s'il y a un loader
	// 	// showLoader( url );

	// 	// Ou on joue une simple redirection
	// 	window.location.href = url;
	// };

	/*
	##          #######      ###     ########   ########   ########   
	##         ##     ##    ## ##    ##     ##  ##         ##     ##  
	##         ##     ##   ##   ##   ##     ##  ##         ##     ##  
	##         ##     ##  ##     ##  ##     ##  ######     ########   
	##         ##     ##  #########  ##     ##  ##         ##   ##    
	##         ##     ##  ##     ##  ##     ##  ##         ##    ##   
	########    #######   ##     ##  ########   ########   ##     ##  
	*/
	// var showLoader = function(url) {
	// 	tl = new TimelineLite();
	// 	tl.pause();
	// 	tl.add([
	// 		// On peut chainer les évènements TweenMax ici. Example :
	// 		// TweenMax.fromTo( $pan2l, 0,   { opacity: 0 }, { opacity: 1, delay: 0.10 } ),
	// 		// TweenMax.fromTo( $pan3l, 0,   { opacity: 0 }, { opacity: 1, delay: 0.20 } ),
	// 		// TweenMax.fromTo( $logo,  0.1, { opacity: 0 }, { opacity: 1, delay: 0.20 } )
	// 	]);
	// 	tl.call(function() {
	// 		// A la fin de l'anim, on redirige vers la nouvelle URLs
	// 		window.location.href = url;
	// 	});
	// 	tl.play();
	// };

	// var hideLoader = function() {
	// 	// On lance l'animation de révélation de la page
	// 	tl = new TimelineLite();
	// 	tl.pause();
	// 	tl.call(function() {
	// 		initScrollAnimations();
	// 	});
	// 	tl.add([
	// 		// On peut chainer les évènements TweenMax ici. Example :
	// 		// TweenMax.fromTo( $pan1, 0.4, { x: '0' }, { x: '100%', delay: 0 } ),
	// 		// TweenMax.fromTo( $pan2, 0.4, { x: '0' }, { x: '100%', delay: 0.15 } ),
	// 		// TweenMax.fromTo( $logo, 0.2, { opacity: 1 }, { opacity: 0, delay: 0  } )
			
	// 	]);	
	// 	tl.play();
	// };

	/*
	####  ##    ##   ####  ########   
	 ##   ###   ##    ##      ##      
	 ##   ####  ##    ##      ##      
	 ##   ## ## ##    ##      ##      
	 ##   ##  ####    ##      ##      
	 ##   ##   ###    ##      ##      
	####  ##    ##   ####     ##      
	*/

	var pageInit = function() {
		initParallax();
		
		if ($('body').hasClass('home')) {
			initNav();
		}
		$(window).trigger('resize');

		// /* Autres scripts qui doivent être initialisés */
		// slider.init();

		// LOCAL SMOOTH SCROLL
		$('.localscroll').localScroll({
			hash: true
		});
		$.localScroll.hash();

		// Fitvids
		// $('.fitvids').fitVids();

		// $('body').imagesLoaded( { background: true })
		// // .progress( function( instance, image ) {
		// // 	NProgress.inc();
		// // })
		// .always( function(instance) {
		// 	// NProgress.done();
		// 	hideLoader();
		// });
	};

	var initScrollAnimations = function() {
		var controllerScroll = new ScrollMagic.Controller();
		
		$('.page .scroll-reveal').each(function() {
			var element = $(this);
			var offset  = element.offset().top;
			var windowHeight  = $(window).height();
			var scene   = new ScrollMagic.Scene({
				triggerHook: "onLeave",
				offset: offset - ( windowHeight * 0.85 ),
				reverse: false
			});
			scene.on('start', function(e) {
				if (e.scrollDirection != 'REVERSE') {
					element.trigger('reveal');
					scene.remove();
				}
			});
			scene.addTo(controllerScroll);
		});	
		$('.page').data('scrollController', controllerScroll);
	};

	/*
	########   ########   ##     ##  ########      ###     ##         
	##     ##  ##         ##     ##  ##           ## ##    ##         
	##     ##  ##         ##     ##  ##          ##   ##   ##         
	########   ######     ##     ##  ######     ##     ##  ##         
	##   ##    ##          ##   ##   ##         #########  ##         
	##    ##   ##           ## ##    ##         ##     ##  ##         
	##     ##  ########      ###     ########   ##     ##  ########   
	*/
	var scrollRevealHandler = function() {
		var element = $(this);

		if (element.hasClass('is-revealed'))
			return;

		if (element.is('.text')) {
			splitTitle = new SplitText(element.find('span'),{
				type:       'words',
				wordsClass: 'split-word'
			});

			lines = element.find('span');
			words = element.find('.split-word');

			words.css({
				opacity: 0
			});
			element.find('span').css({
				opacity: 1
			});

			element.addClass('is-revealed');

			tl = new TimelineLite();
			tl.pause();

			tl.fromTo(arrow, 1, {
				alpha: 0
			}, {
				alpha: 1,
			});

			lines.each(function(index) {
				var line = $(this);
				tl.fromTo(line.find('.split-word'), 0.8, {
					alpha: 0,
					top: line.height()
				}, {
					alpha: 1,
					top: 0,
					ease: Power3.easeOut
				}, 0 + (index * 0.1));
			});
			tl.call(function() {
				splitTitle.revert();
			});
			tl.play();

		} else if (element.is('.lined-text')) {
			splitTitle = new SplitText(element.find('p'),{
				type:       'lines',
				linesClass: 'split-line'
			});

			lines = element.find('.split-line');

			lines.css({
				opacity: 0
			});
			element.find('p').css({
				opacity: 1
			});

			element.addClass('is-revealed');

			tl = new TimelineLite();
			tl.pause();

			lines.each(function(index) {
				var line = $(this);
				tl.fromTo( line, 0.8, {
					alpha: 0,
					top: line.height()
				}, {
					alpha: 1,
					top: 0,
					ease: Power3.easeOut
				}, 0 + (index * 0.1));
			});
			tl.call(function() {
				splitTitle.revert();
			});
			tl.play();
		
		} else if (element.is('.title')) {
			splitTitle = new SplitText(element,{
				type:       'lines',
				linesClass: 'split-line'
			});

			lines = element.find('.split-line');

			lines.css({
				opacity: 0
			});
			element.css({
				opacity: 1
			});

			element.addClass('is-revealed');

			tl = new TimelineLite();
			tl.pause();

			lines.each(function(index) {
				var line = $(this);
				tl.fromTo( line, 0.8, {
					alpha: 0,
					top: line.height()
				}, {
					alpha: 1,
					top: 0,
					ease: Power3.easeOut
				}, 0 + (index * 0.1));
			});
			tl.call(function() {
				splitTitle.revert();
			});
			tl.play();

		} else if (element.is('.vertical-title')) {
			

			

			element.css({
				opacity: 1
			});

			element.addClass('is-revealed');

			tl = new TimelineLite();
			tl.pause();

			tl.fromTo( element, 0.8, {
				alpha: 0,
			}, {
				alpha: 1,
				ease: Power3.easeOut
			});
			tl.play();

		} else if (element.is('.editor-text')) {
			p = $(this).find('p, li');
					
			element.addClass('is-revealed');

			p.css({
				opacity: 0
			});

			tl = new TimelineLite();
			tl.pause();
			
			p.each(function(index) {
				tl.fromTo($(this), 0.8, {
					alpha: 0,
					y: 20
				}, {
					alpha: 1,
					y: 0,
					ease: Power3.easeOut
				}, 0 + (index * 0.1));
			});
			tl.play();

		} else if (element.is('.stagger-show')) {
			element.addClass('is-revealed');

			square = $(this).find('.stagger-show-item');

			var duration = 0.5;
			var stagger  = 0.2;
			var animationClass = 'show';
			TweenMax.staggerTo(square, duration, {className: '+=' + animationClass}, stagger);
		}
	};

	/*
	########      ###     ########      ###     ##         ##            ###     ##     ##  
	##     ##    ## ##    ##     ##    ## ##    ##         ##           ## ##     ##   ##   
	##     ##   ##   ##   ##     ##   ##   ##   ##         ##          ##   ##     ## ##    
	########   ##     ##  ########   ##     ##  ##         ##         ##     ##     ###     
	##         #########  ##   ##    #########  ##         ##         #########    ## ##    
	##         ##     ##  ##    ##   ##     ##  ##         ##         ##     ##   ##   ##   
	##         ##     ##  ##     ##  ##     ##  ########   ########   ##     ##  ##     ##  
	*/
	var ctrlParallax;
	var sceneParallax = [];
	var initParallax = function() {
		if ( $(window).width() > 992 ) {
			ctrlParallax = new ScrollMagic.Controller();
			$('body').addClass('ctrlParallax');
		}

		$('.parallax').each(function( index ) {
			$(this).css({ transform: '' });
			
			if ( $(window).width() > 992 ) {
				var start      = $(this).attr( 'data-start' );
				var end        = $(this).attr( 'data-end' );
				var direction  = $(this).attr( 'data-direction' );
				if ( start     === undefined ) start = 0;
				if ( end       === undefined ) end = 0;
				if ( direction === undefined ) direction = "vertical";

				var windowHeight  = $(window).height();
				var elementOffset = $(this).offset().top;
				var elementHeight = $(this).outerHeight();
				var offset        = ( elementOffset - windowHeight );
				
				var tween;
				if ( direction == 'horizontal' ) {
					tween = TweenMax.fromTo( this, 1, { x: start }, { x: end, ease: Power0.easeNone } );
				} else {
					tween = TweenMax.fromTo( this, 1, { y: start }, { y: end, ease: Power0.easeNone } );
				}
				
				sceneParallax[index] = new ScrollMagic.Scene({
					offset: offset,
					triggerHook: 'onEnter'
				})
				.setTween( tween )
				.addTo( ctrlParallax );
			}
		});
	};

	var resizeParallax = function() {
		if ( $(window).width() > 992 )  {
			if ( ! $('body').hasClass('ctrlParallax') ) {
				initParallax();
			} else {
				ctrlParallax.enabled(true);
				ctrlParallax.update(true);
			}
		} else {
			if ( $('body').hasClass('ctrlParallax') ) {
				$('body').removeClass('ctrlParallax');
				ctrlParallax.destroy(true);
			}
		}

		if ( $(window).width() > 992 ) {
			if ( ctrlParallax.enabled() ) {
				$('.parallax').each(function( index ) {
				
					var elementDuration  = $(this).attr( 'data-duration' );
					if ( elementDuration === undefined ) elementDuration = 1;

					var windowHeight  = $(window).height();
					var elementOffset = $(this).offset().top;
					var elementHeight = $(this).outerHeight();
					var offset        = ( elementOffset - windowHeight );
					var duration      = ( windowHeight  + elementHeight );

					sceneParallax[index].duration(duration);
					sceneParallax[index].offset(offset);
					sceneParallax[index].update(true);
				}); 
			} 
		} else {
			$('.parallax').each(function( index ) {
				$(this).css({ transform: '' });
			});
		}	
	};

	/*
	 ######    ##         ####  ########   ########   ########   
	##    ##   ##          ##   ##     ##  ##         ##     ##  
	##         ##          ##   ##     ##  ##         ##     ##  
	 ######    ##          ##   ##     ##  ######     ########   
	      ##   ##          ##   ##     ##  ##         ##   ##    
	##    ##   ##          ##   ##     ##  ##         ##    ##   
	 ######    ########   ####  ########   ########   ##     ## 
	*/

	// /* EXAMPLE DE FONCTION COMPLEMENATAIRE
	var slider = (function() {
		var init = function() {
			// kjqshlqksch
		};
		return {
	        init: init
	    };
	})();

	// var resizeSlider = function() {
	// 	var sliderWidth  = $('.slider').innerWidth();
	// 	var sliderHeight = $('.slider').innerHeight();


	// 	$('.slide .img').each(function(index) {
	// 		$(this).css('width', sliderWidth).css('height', sliderHeight);
	// 	});
	// };


	return {
		init: init
	};

// END SITE
})();
site.init();

})(jQuery);
